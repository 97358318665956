 aside.sidebar {
      height: 100%;
      width: 2.5vw;
      position: fixed;
      right: 0;
      top: 0;
      background: #362e2b;
      z-index: 4;

   }

   aside.sidebar ul {
      float: left;
      padding: 1px;
   }
   aside.sidebar ul li{
      list-style: none;
      float: left;
      width: 100%;
      box-sizing: border-box;
      height: 2.5vw;
      margin-top: 5px;
      position: relative;
   }
   aside.sidebar ul li:hover{
      background: #ffd452;
   }
   aside.sidebar ul li.slot2:hover:after, aside.sidebar ul li.slot4:hover:after{
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      box-sizing: content-box;
      border: solid 0.5vw transparent;
      border-right-color: #ffd452;
      left: -0.9vw;
      top: 0.8vw;

      z-index: 2;
   }
   aside.sidebar ul li a{
      float: left;
      width: 100%;
      height: 100%;
   }
   aside.sidebar ul li span.icon{
      background: url("/images/sprites/sidebar-icons.png");
      background-repeat: no-repeat;
      display: block;
      margin:auto;
      position: relative;
      top: 0.5vw;
   }
   aside.sidebar ul li.slot1 span.icon{
      background-position: -5px -5px;
      width: 29px;
      height: 23px;
   
   }
   aside.sidebar ul li.slot2 span.icon{
         width: 27px;
    height: 25px;
    background-position: -44px -36px;

   }
   aside.sidebar ul li.slot3 span.icon{
      width: 23px;
    height: 23px;
    background-position: -81px -36px;

   }
   aside.sidebar ul li.slot4 span.icon{
      width: 21px;
    height: 21px;
    background-position: -76px -5px;
   }
   aside.sidebar ul li.slot5 span.icon{
      width: 22px;
    height: 21px;
    background-position: -44px -5px;
   }
   aside.sidebar ul li.slot6 span.icon{
      width: 20px;
    height: 12px;
    background-position: -5px -69px;
    top: 0.8vw;

   }
   aside.sidebar ul li.slot7 .content-long.mod-shorter{
      width: 10vw;
      left: -10.1vw;
    height: initial;
    
   }
   aside.sidebar ul li:first-child {
      margin-top: 10vw;
   }
   aside.sidebar ul li:nth-child(6) {
      background: #ffd452;
   }

   aside.sidebar ul li span.content-short {
      position: absolute;
      left: -6.1vw;
      top: 0;
      padding:  0.5vw 1.2vw;
      display: none;
      background: #ec6941;
      color: white;
      height: 100%;
      font-size: 0.9vw;
   }
   aside.sidebar ul li:hover span.content-short:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      box-sizing: content-box;
      border: solid 0.5vw transparent;
      border-left-color: #ec6941;
      right: -0.9vw;
      top: 0.8vw;
      z-index: 2;
   }
   aside.sidebar ul li:hover span.content-short {
      display: block;
   }

   aside.sidebar ul li div.content-long {
      position: absolute;
      left: -30.1vw;
      top: 0;
      display: none;
      background: #ec6941;
      color: white;
      width: 30vw;
      height: 301px;
      font-size: 0.9vw;
      cursor: default;
   }
   aside.sidebar ul li div.content-long.mod-shorter {
      left: -20.1vw;
      width: 20vw;
      color: white;
   }
   aside.sidebar ul li:hover div.content-long {
      display: block;
   }
  
   .content-long .content-long-header {
      border-bottom: solid 2px rgba(255,255,255,.3);
      margin-top: 10px;

   }
   .content-long .content-long-header h4{
      color: white;
      font-size: 1.1vw;
      text-indent: 1vw;

   }

   div.content-long-content div.row div {
      display: inline-block;
      padding-top: 0.5vw;
      padding-bottom: 0.5vw;
   }
   div.content-long-content div.row div.mod-inversed {
      background: #fc8b69;
   }
   div.content-long-content div.row div:hover, div.content-long-content div.row div.mod-inversed:hover {
      background: #362e2b;
   }
   div.content-long-content div.row div:hover span.list-deleteIcon {
      display: inline-block;
      color: #ffd452;
   }
   div.content-long-content div.row div:hover span.list-gameProvider {
      color: #ffd452;
      border-color: #ffd452;
   }
   div.content-long-content div.row div:hover span.list-styler {
      color: black;
      background: #ffd452;
   }
   div.content-long-content div.row div:hover .list-gameName {
      color: #ffd452;
   }

   div.content-long-content div span {
      display: inline-block;
   }
   div.content-long-content div.row div span.list-gameName {
      cursor: pointer;
   }

   div.content-long-content div span.list-gameProvider, div.content-long-content div span.list-deleteIcon {
      
      font-size: 0.7em;
      vertical-align: middle;
      padding-left: 2px;
      padding-right: 2px;
      vertical-align: 1px;
   }

   div.content-long-content div span.list-gameProvider {
      color: black;
      border: solid 1px black;
   }

   div.content-long-content div span.list-deleteIcon {
      float: right;
      font-weight: bold;
      display: none;
      color: #ffd452;
      border: solid 1px #ffd452;
      cursor: pointer;
   }

   div.content-long-content div span.list-styler {
      background: black;
      border-radius: 50%;
      display: inline-block;
      width: 1vw;
      height: 1vw;
      font-size: 0.8vw;
      text-align: center;
   }


   aside.sidebar ul li:hover a div.content-long, aside.sidebar ul li:hover a span.content-short {
      
      transform: translate(0,0);
      animation: moveSlightlyRight 0.4s 1;
   } 

   @keyframes moveSlightlyRight {
      0% { opacity: 0; transform: translate(-20px, 0); }
      100% { opacity: 1; transform: translate(0,0); }
   }
